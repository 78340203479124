
  import { defineComponent, reactive, toRefs, onBeforeMount } from 'vue';
  import { getUserSystem } from '@/api/userinfo';
  import rightArrowIconWite from '../../assets/image/rightArrowIcon.png';
  import rightArrowIconBlack from '../../assets/image/rightArrowIconBlack.png';
  import salaryRightIcon from '../../assets/image/salary_rightIcon.png';
  import day from '../../assets/image/day.png';
  import dayActive from '../../assets/image/dayActive.png';
  import gz from '../../assets/image/gz.png';
  import gzActive from '../../assets/image/gzActive.png';
  import fw from '../../assets/image/fw.png';
  import fwActive from '../../assets/image/fwActive.png';

  export default defineComponent({
    setup() {
      const state = reactive({
        rightArrowIcon: [rightArrowIconBlack, rightArrowIconBlack],
        salaryRightIcon,
        salaryRightIconImg: 'salaryRightIcon',
        cardStyle: {
          padding: '0px',
        },
        currentIndex: 0, // 标记点击时背景色变化
        cardPicture: [
          {
            name: '招聘系统',
            code: 'shanks-manager-backend',
            baseImg: gz,
            hoveImg: gzActive,
            linearColor: 'linearColor',
            linearColorActive: 'linearColorActive',
            arrow: rightArrowIconBlack,
            arrowActive: rightArrowIconWite,
          },
          {
            name: '发薪系统',
            code: 'tezzolo-finance-manager',
            baseImg: day,
            hoveImg: dayActive,
            linearColor: 'linearColor',
            linearColorActive: 'linearColorActive',
            arrow: rightArrowIconBlack,
            arrowActive: rightArrowIconWite,
          },
          {
            baseImg: fw,
            hoveImg: fwActive,
            linearColor: 'linearColor',
            linearColorActive: 'linearColorActive',
            arrow: rightArrowIconBlack,
            arrowActive: rightArrowIconWite,
          },
        ],
        cardData: [],
      });
      const changeSystem = (item: any, index: number): void => {
        window.open(item.url);
        state.currentIndex = index;
      };
      const getSystemData = async () => {
        const res: any = await getUserSystem();
        const systemList = res.data;
        state.cardData = systemList.map((item: any, index: any) => {
          const card = state.cardPicture.filter((o) => o.code === item.code);
          return { ...card[0], ...item };
        });
      };
      const onMouseover = (item: any, index: any) => {
        console.log(item, index);
        const data: any = state.cardData;
        state.cardData = data.map((f: any, i: any) => ({ ...f, overBg: i === index }));
      };
      const onmouseleave = (item: any, index: any) => {
        const data: any = state.cardData;
        state.cardData = data.map((f: any, i: any) => ({ ...f, overBg: !i === index }));
      };
      onBeforeMount(() => {
        getSystemData();
      });

      return {
        ...toRefs(state),
        changeSystem,
        getSystemData,
        onMouseover,
        onmouseleave,
      };
    },
  });
